<template>
    <div class="hi100 uf uf-column message-list">
        <el-table
                class="uf-f1"
                :data="dataList"
                border
                v-loading="dataListLoading"
                style="width: 100%;">
            <el-table-column type="index" header-align="center" align="center" width="100" label="序号" :index="indexMethodService"></el-table-column>
            <el-table-column prop="userName" header-align="center" align="center" label="医生姓名">
                <template slot-scope="scope">
                    <el-badge :value="scope.row.unreadCount" class="item" :max="99" :hidden="scope.row.unreadCount <= 0">
                        <div v-if="scope.row.sysUserEntity" class="lh1">{{scope.row.sysUserEntity.name}}</div>
                    </el-badge>
                </template>
            </el-table-column>
            <el-table-column prop="phone" header-align="center" align="center" label="未读消息"  width="150px">
                <template slot-scope="scope">
                    <span>{{scope.row.unreadCount}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="phone" header-align="center" align="center" label="最近一条消息类型" width="180px">
                <template slot-scope="scope">
                    <span v-if="scope.row.bsRoomMsgEntity">{{scope.row.bsRoomMsgEntity.msgType === 'TEXT' ? '文本' : '图片'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="petName" header-align="center" align="center" label="最近一条消息内容">
                <template slot-scope="scope">
                   <div v-if="scope.row.bsRoomMsgEntity">
                       <span v-if="scope.row.bsRoomMsgEntity.msgType === 'TEXT'">{{scope.row.bsRoomMsgEntity.msgText}}</span>
                       <el-link :href="$cons.SERVER_PATH + scope.row.bsRoomMsgEntity.msgText" target="_blank" v-else>点击查看图片</el-link>
                  </div>
                </template>
            </el-table-column>
            <el-table-column prop="phone" header-align="center" align="center" label="发送时间">
                <template slot-scope="scope">
                    <span v-if="scope.row.bsRoomMsgEntity">{{scope.row.bsRoomMsgEntity.sendTime}}</span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" header-align="center" align="center" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="onlineDiagnosis(scope.row.sysUserEntity)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="uf uf-ac uf-pe mt-3">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalPage"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'message-list',
        data () {
            return {
                dataForm: {},
                industryList: [],
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListSelections: [],
                dataListLoading: false,
                // 聊天框--显隐控制
                chatBoxVisiable: false,
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            picker.$emit('pick', [start, end])
                        }
                    }, {
                        text: '最近一个月',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            picker.$emit('pick', [start, end])
                        }
                    }, {
                        text: '最近三个月',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                            picker.$emit('pick', [start, end])
                        }
                    }]
                },
                dict: {},
                industryDict: {},
                sourceDict: {}
            }
        },
        created () {
            this.$getDictList('INDUSTRY,DATA_SOURCE', dict => {
                this.dict = dict || {}
            })
            this.$getDictMap('INDUSTRY,DATA_SOURCE', dict => {
                this.industryDict = dict['INDUSTRY']
                this.sourceDict = dict['DATA_SOURCE']
            })
            this.$nextTick(() => {
                this.getDataList()
            })
        },
        methods: {
            //  获取消息列表
            getDataList (isPage = false) {
                if (isPage) {
                    this.pageIndex = 1
                }
                this.dataListLoading = true
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.MESSAGE_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.pageIndex,
                        limit: this.pageSize,
                    })
                }).then(({data}) => {
                    this.dataListLoading = false
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        this.dataList = page.list || []
                        this.totalPage = page.totalCount || 0
                    }
                })
            },
            // 咨询
            onlineDiagnosis (item) {
                this.$emit('onlineDiagnosis', item)
            },
            closeCallBack () {
                this.chatBoxVisiable = false
                this.getDataList()
            },
            // 每页数
            sizeChangeHandle (val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle (val) {
                this.pageIndex = val
                this.getDataList(true)
            },
            indexMethodService (index) {
                return (this.pageIndex - 1) * this.pageSize + (index + 1)
            },
        }
    }
</script>

<style scoped>
    .message-list /deep/ .el-table .cell {
        overflow: inherit;
    }
</style>
