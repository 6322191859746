<template>
    <div class="hi100 uf uf-column">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item label="医生姓名">
                <el-input v-model="dataForm.userName" placeholder="请输入医生姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话">
                <el-input v-model="dataForm.phone" placeholder="请输入电话"></el-input>
            </el-form-item>
            <el-form-item label="行业">
                <el-select v-model="dataForm.industryScope" placeholder="请选择行业" clearable>
                    <el-option
                            v-for="item in dict['INDUSTRY']"
                            :key="item.dictId"
                            :label="item.dictName"
                            :value="item.dictId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="专家来源">
                <el-select v-model="dataForm.source" placeholder="请选择专家来源" clearable>
                    <el-option
                            v-for="item in dict['DATA_SOURCE']"
                            :key="item.dictId"
                            :label="item.dictName"
                            :value="item.dictId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="入驻时间">
                <el-date-picker
                        v-model="dataForm.dateRange"
                        type="daterange"
                        unlink-panels
                        :clearable="true"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button @click="getDataList()" type="primary">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table
                class="uf-f1"
                :data="dataList"
                border
                v-loading="dataListLoading"
                style="width: 100%;">
            <el-table-column type="index" header-align="center" align="center" width="100" label="序号" :index="indexMethodService"></el-table-column>
            <el-table-column prop="userName" header-align="center" align="center" label="医生姓名"></el-table-column>
            <el-table-column prop="petName" header-align="center" align="center" label="昵称"></el-table-column>
            <el-table-column prop="phone" header-align="center" align="center" label="联系电话"></el-table-column>
            <el-table-column prop="industryScope" header-align="center" align="center" show-overflow-tooltip label="行业">
                <template slot-scope="scope">
                    <span>{{industryDict[scope.row.industryScope]}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="source" header-align="center" align="center" show-overflow-tooltip label="专家来源">
                <template slot-scope="scope">
                    <span>{{sourceDict[scope.row.source]}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" header-align="center" align="center" label="入驻时间"></el-table-column>
            <el-table-column fixed="right" header-align="center" align="center" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="onlineDiagnosis(scope.row.sysUserEntity)">咨询</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="uf uf-ac uf-pe mt-3">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalPage"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'doctor-list',
        data () {
            return {
                activeName: 'first',
                dataForm: {
                    userName: '',
                    phone: '',
                    industryScope: '',
                    source: '',
                    dateRange: []
                },
                industryList: [],
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListSelections: [],
                dataListLoading: false,
                // 聊天框--显隐控制
                chatBoxVisiable: false,
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            picker.$emit('pick', [start, end])
                        }
                    }, {
                        text: '最近一个月',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            picker.$emit('pick', [start, end])
                        }
                    }, {
                        text: '最近三个月',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                            picker.$emit('pick', [start, end])
                        }
                    }]
                },
                dict: {},
                industryDict: {},
                sourceDict: {}
            }
        },
        created () {
            this.$getDictList('INDUSTRY,DATA_SOURCE', dict => {
                this.dict = dict || {}
            })
            this.$getDictMap('INDUSTRY,DATA_SOURCE', dict => {
                this.industryDict = dict['INDUSTRY']
                this.sourceDict = dict['DATA_SOURCE']
            })
            this.$nextTick(() => {
                this.getDataList()
            })
        },
        methods: {
            //  获取质量医生列表
            getDataList (isPage = false) {
                if (isPage) {
                    this.pageIndex = 1
                }
                this.dataListLoading = true
                let time = this.$deepClone(this.dataForm.dateRange) || []
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.DOCTOR_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.pageIndex,
                        limit: this.pageSize,
                        userName: this.dataForm.userName,
                        phone: this.dataForm.phone,
                        industryScope: this.dataForm.industryScope,
                        source: this.dataForm.source,
                        startCreateTime: time && time.length > 0 ? time[0] + ' 00:00:00' : '',
                        endCreateTime: time && time.length > 0 ? time[1] + ' 23:59:59' : ''
                    })
                }).then(({data}) => {
                    this.dataListLoading = false
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        this.dataList = page.list || []
                        this.totalPage = page.totalCount || 0
                    }
                })
            },
            // 咨询
            onlineDiagnosis (item) {
                this.$emit('onlineDiagnosis', item)
            },
            closeCallBack () {
                this.chatBoxVisiable = false
                this.getDataList()
            },
            // 每页数
            sizeChangeHandle (val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle (val) {
                this.pageIndex = val
                this.getDataList(true)
            },
            indexMethodService (index) {
                return (this.pageIndex - 1) * this.pageSize + (index + 1)
            },
        }
    }
</script>

<style scoped>

</style>