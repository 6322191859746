<template>
    <div class="online-diagnosis-list uf uf-column">
        <div class="zxw-nav" style="padding-left: 0px;padding-bottom: 10px">
            <div class="wrap pr">
                <div class="uf uf-pc">
                    <li class="" @click="$router.push({ name: 'serve' })">首页</li>
                    <li class="pr" @click="$router.push({ name: 'index' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">自助式服务</span>
                    </li>
                    <li class="pr" @click="$router.push({ name: 'tech-analysis' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">推荐式服务</span>
                    </li>
                    <li class="pr" @click="$router.push({ name: 'quality-doctor' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">一站式服务</span>
                    </li>
                    <li style="list-style-type: none" @click="toStandardbzfw">
                        标准服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardqyxycx">
                        企业信用查询
                    </li>
                    <li style="list-style-type: none" @click="toStandardzcfw">
                        政策服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardzwqyml">
                        小微企业名录
                    </li>
                    <li style="list-style-type: none" @click="toStandardrzrkfw">
                        认证认可服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardsbjs">
                        商标工作台
                    </li>
                    <li style="list-style-type: none" @click="toSCRM">SCRM</li>
                </div>
            </div>
        </div>
        <div style="width:100%;margin: 10px auto;" class="uf-f1">
            <el-card style="height: 100%;">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="质量医生" name="first"></el-tab-pane>
                    <el-tab-pane label="消息列表" name="second"></el-tab-pane>
                </el-tabs>
                <div v-if="activeName === 'first'" class="uf-f1">
                    <doctorList @onlineDiagnosis="onlineDiagnosis"></doctorList>
                </div>
                <div v-if="activeName === 'second'" class="uf-f1">
                    <messageList @onlineDiagnosis="onlineDiagnosis" ref="messageRef"></messageList>
                </div>
            </el-card>
        </div>
<!--        聊天框-->
        <chatBox v-if="chatBoxVisiable" ref="chatBoxRef" @closeCallBack="closeCallBack"></chatBox>
    </div>
</template>

<script>
    import chatBox from '../../components/chat-box'
    import doctorList from './component/doctor-list'
    import messageList from './component/message-list'
    export default {
      name: 'list',
        components: {
            chatBox,
            doctorList,
            messageList
        },
        data () {
          return {
              activeName: 'first',
              // 聊天框--显隐控制
              chatBoxVisiable: false,
          }
        },
        created () {},
        methods: {
            handleClick () {},
            onlineDiagnosis (item) {
                this.chatBoxVisiable = true
                this.$nextTick(() => {
                    this.$refs.chatBoxRef.init(item)
                })
            },
            closeCallBack () {
                this.chatBoxVisiable = false
                if (this.activeName === 'second') {
                    this.$nextTick(() => {
                        this.$refs.messageRef.getDataList()
                    })
                }
            },
            // 顶部 tab 标签 切换时跳转的网址
            toStandardbzfw() {
                window.open('http://std.samr.gov.cn/')
            },
            toStandardzcfw() {
                window.open('http://gjzwfw.www.gov.cn/col/col644/index.html')
            },
            toStandardqyxycx() {
                window.open('http://www.gsxt.gov.cn/index.html')
            },
            toStandardzwqyml() {
                window.open('http://xwqy.gsxt.gov.cn/')
            },
            toStandardrzrkfw() {
                window.open('http://cx.cnca.cn/CertECloud/index/index/page')
            },
            toStandardsbjs() {
                window.open('https://tm.gongsibao.com/tm/tmdashboard')
            },
            toSCRM() {
                window.open('https://scrm.gongsibao.com/home.html')
            }
        }
    }
</script>

<style scoped>
    .online-diagnosis-list {
        height: calc(100vh - 120px);
    }
    .online-diagnosis-list /deep/ .el-card__body {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .zxw-nav {
        /*background: linear-gradient(90deg, #35bce2, #409eff);*/
        background: white;
        line-height: 2.4rem;
        width: 100%;
        outline: none;
    }

    .zxw-nav .wrap {
        width: 1300px;
        margin: 0 auto;
    }

    .zxw-nav li {
        width: 7rem;
        color: #404040;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
    }

    .zxw-nav li:hover,
    .zxw-nav li.on {
        background: #69c2ff;
        font-size: 15px;
        color: white;
    }
</style>
